import React from "react"
import Layout from "@components/kaigai-fx/layout";
import parse from "html-react-parser"
import { GatsbyImage } from "gatsby-plugin-image"

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination } from "swiper";

// Import SVG
import icon_t_SVG1 from '@images/kaigai-fx/cat_icon01.svg';
import icon_t_SVG3 from '@images/kaigai-fx/cat_icon02.svg';
import icon_t_SVG4 from '@images/kaigai-fx/cat_icon03.svg';
import topPromo from '@images/kaigai-fx/mv_pc01.webp';
import topPromoSp from '@images/kaigai-fx/mv_sp01.webp';
import icon_sns_SVG1 from '@images/kaigai-fx/sns_icon1.svg';
import icon_sns_SVG2 from '@images/kaigai-fx/sns_icon2.svg';

// 関数呼び出し
import { GraphqlArray } from "@components/kaigai-fx/graphql-data-post.js";
import { FixedFoot } from "@components/kaigai-fx/footer.js";
import { SeoContents } from "@components/kaigai-fx/seo-data.js" // Seo情報設定
import { ColorClassSet } from "@components/kaigai-fx/color-class-set.js" //

const FxIndex = () => {
  let fixedItem = FixedFoot( 'nomal' ); // 固定フッター
  
  // Seo情報設定
  const pathName = 'index';
  const seoTag = SeoContents({ Path:pathName });
  
  let setName, setPostData, setArray;
  let pickupCount = 6 // 記事表示数
  // お勧めの記事スライダー
  setName = 'pickup';
  setPostData = GraphqlArray(setName, 'setTag'); // pickupタグを設定
  setArray = makeArray( setPostData.checkTag, pickupCount); // タグフィルタ

  const pickupPath = "tags/" + setName + "/";
    
  let backImage, backImageUrl, setClass ;
  const pickupList = setArray.map((result) => {
    backImage = result.Image ? result.Image.images.fallback.src : "" ; // 背景画像データ
    backImageUrl = 'url("' + backImage + '")' ; // 背景画像URL設定
    setClass = ColorClassSet( result.MainCateg, 'archive' ); // メインカテゴリ別クラス名取得
    return  <div className="md:max-w-[320px] . max-w-[380px]">
              <a href={result.Title + "/"} className="Campaign inline-block mb-5">
                <div className={"rounded-r-[15px] min-h-[150px]  md:aspect-[13/11] overflow-hidden shadow-lg relative" + setClass}>
                  <div className={"w-full bg-center bg-cover"} style={{ aspectRatio: "25/14" ,backgroundImage: backImageUrl}}>
                  <div className="after:content-[''] bg-gray-800 after:absolute; bg-opacity-30 after:inset-0 max-w-[500px] w-full h-full">
                    <div className={"flex items-center justify-center h-full md:text-[16px] text-[18px] font-bold text-white p-3 text-center"}>{result.Title}</div>
                    </div>
                  </div>
                  <div className="pb-3 pt-2 px-3">
                    <p className="md:text-[13px] text-[14px] mt-[4px]">{parse(result.Excerpt)}</p>
                  </div>
                </div>
              </a>
            </div> ;
    });
  // FX業者の比較スライダー
  setName = '海外FX業者';
  setClass = ColorClassSet( setName, 'archive' );  
  setPostData = GraphqlArray(setName, 'setCateg'); // 海外FX業者カテゴリを設定
  setArray = makeArray( setPostData.checkCateg, pickupCount); // カテゴリフィルタ
  const comparPath = "categories/" + setName + "/";
  const comparFxList = setArray.map((result) => {
    backImage = result.Image ? result.Image.images.fallback.src : "" ; // 背景画像データ
    backImageUrl = 'url("' + backImage + '")' ; // 背景画像URL設定
    // if ( result.Title === undefined ) {
    //   return
    // }
    return  <div className="md:max-w-[320px] max-w-[380px] my-0 mx-auto">
              <a href={result.Title + "/"} className="Campaign w-full inline-block mb-5">
                <div className={"flex md:block w-full aspect-[12/4]  md:aspect-[13/11] rounded-r-[15px] overflow-hidden shadow-lg bg-white relative" + setClass}>
                  <div className="w-full flex md:block md:max-w-[320px] ">
                    <div className={"PC hidden md:block w-full bg-center bg-cover"} style={{ aspectRatio: "25/14" ,backgroundImage: backImageUrl}}>
                    <div className="md:after:content-[''] md:bg-gray-800 md:after:absolute; md:bg-opacity-30 md:after:inset-0 md:max-w-[500px] w-full h-full">
                      <div className={"flex items-center justify-center h-full text-[18px] font-bold text-white"}>{result.Title}</div>
                      </div>
                    </div>
                    <div className={"MB w-[30%] md:hidden overflow-hidden bg-center bg-cover"} style={{backgroundImage: backImageUrl}}></div>
                    <div className="w-[70%] md:w-full md:w-full pb-3 pt-2 px-3 "> 
                      <p className="CampP md:hidden md:text-[14px] text-[13px] font-bold">{result.Title}</p>
                      <p className="md:text-[13px] text-[11px] mt-[4px]">{parse(result.Excerpt)}</p>
                    </div>




                  </div>
                </div>
              </a>
            </div> ;
    });

  // 新着情報
  setPostData = GraphqlArray('', 'setAll'); 
  setArray = setPostData.allData
  let setListArray = [];
  let pushArray = [];
  if (setArray[0] !== undefined) { // 記事０のときのエラー回避
    for (let i = 0; i < 3; i++) {
      setListArray.push({Title:setArray[i].Title, MixDate:setArray[i].Date, Word:'が作成されました。'})
    }
    const setArray2 = setArray.sort((a, b) => Date.parse(b.Modified) - Date.parse(a.Modified));
    for (let i = 0; i < 3; i++) {
      setListArray.push({Title:setArray2[i].Title, MixDate:setArray2[i].Modified, Word:'が更新されました。'})
    }
    setListArray.sort((a, b) => Date.parse(b.MixDate) - Date.parse(a.MixDate));
    
    for (let i = 0; i < 3; i++) {
      pushArray.push({Title:setListArray[i].Title, MixDate:setListArray[i].MixDate, Word:setListArray[i].Word})
    }
  }

  const pushList = pushArray.map((result) => {
    return  <>
              <dt className="font-semibold text-[12px] mb-2 ">{result.MixDate}</dt>
              <dd className="block mb-4 border-b-[1px] border-solid pb-3 border-[#A7A7A7]"><a href={result.Title + "/"} className="hover:text-gray-400">「{result.Title}」{result.Word}</a></dd>
            </>
    });

  // 記事データピックアップ用function
  function makeArray( arrayData, count ) {
    let array = [];
    for ( let i = 0; i < count; i++) {
      if ( arrayData[i] !== undefined){
        array.push(arrayData[i])
      }
    }
    return array
  };

  return (
  <>
    <Layout>
      {seoTag}
      <div>
        <div> 
          {/* FV */}
          <div className="md:w-full md:mb-[60px] mb-[30px] max-w-xl mx-auto md:max-w-[1920px] px-0 ">
            <h1>
            <img className="hidden md:block h-auto w-full" alt="海外FX比較サイト GOOD AT TRADE 海外FX業者の比較やブローカー別に特徴をまとめた比較サイトです。"  src={topPromo} />
            <img className="md:hidden block h-auto w-full" alt="海外FX比較サイト GOOD AT TRADE 海外FX業者の比較やブローカー別に特徴をまとめた比較サイトです。" src={topPromoSp} />
            </h1>
          </div>
        </div>
        
        {/* おすすめの記事 */}
        <div className="text-center mb-5 mt-5 md:mt-0 mx-auto max-w-[250px]">
          <h2 className="font-semibold tracking-wide text-lg md:text-xl first-letter:text-[#0028C9] text-[#424242]">
            オススメの記事
            <span><img className="mb-5 mx-auto h-auto inline pl-2 box-border" alt="" width="36" height="36" src={icon_t_SVG1}/></span>
          </h2>
        </div>
        <div  className="max-w-xl mx-auto md:max-w-2xl lg:max-w-5xl md:text-[14px] md:px-0 px-5  ">
          <div className="flex flex-wrap justify-center md:justify-between" id="loader">{pickupList} </div>
        </div>    
        {/* すべてを見る */}
        <div className="pb-10 pt-2">
          <a href={pickupPath} className="rounded-lg block mx-auto max-w-xs text-center text-[13px] text-white font-bold py-5 px-16 m-2 bg-[#0028C9] hover:bg-[#0028C9]/70">全てを見る</a>
        </div>
        

        {/* FX業者の比較 */}
        <div className="mt-[20px] md:mt-[30px] bg-[#F1F1F1] pt-10">
          <div className="text-center mb-5  md:mb-10 md:mt-10  mx-auto max-w-[250px]">
            <h2 className="font-semibold tracking-wide text-lg md:text-xl first-letter:text-[#FFC400]  text-[#424242] ">FX業者の比較
              <span ><img className="mb-5 mx-auto h-auto inline pl-2 box-border" alt="" width="36" height="30" src={icon_t_SVG3}/></span>
            </h2>
          </div>
          <div className="max-w-xl mx-auto md:max-w-2xl lg:max-w-5xl md:text-[14px] md:px-0 px-5">
            <div className="md:flex md:flex-wrap justify-between" id="loader">{comparFxList}</div>
          </div>
          {/* すべてを見る */}
          <div className="pb-10 pt-2">
            <a href={comparPath} className="rounded-lg block mx-auto max-w-xs text-center text-[13px] text-white font-bold py-5 px-16 my-2 bg-[#FFC400] hover:bg-[#FFC400]/70">全てを見る</a>
          </div>
        </div>

        {/* 新着情報 */}   
        <div className="text-center mb-8 mb-10 mt-[40px] md:mt-[50px] px-5 md:px-0">
          <h2 className="font-semibold tracking-wide text-lg md:text-xl first-letter:text-[#C90065]  text-[#424242] ">新着情報<span ><img className="mb-5 mx-auto h-auto inline pl-2 box-border" alt="" width="38" height="27" src={icon_t_SVG4}/></span></h2>

          <dl className="mt-10 mb-[110px] max-w-5xl mx-auto">
            {pushList}
          </dl>
        </div>

        {/* sns icon */}   
        <div className="w-full max-w-[100px] mx-auto flex justify-between md:mb-0 mb-10">
          <p><img src={icon_sns_SVG1} alt=""></img></p>
          <p><img src={icon_sns_SVG2} alt=""></img></p>
        </div>

      </div>
      {/* 固定フッター */}
      {fixedItem}
    </Layout>  
  </>
  )
}
export default FxIndex